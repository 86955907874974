// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-giraldomac-gatsby-theme-wvumedicine-src-pages-404-js": () => import("./../../../node_modules/@giraldomac/gatsby-theme-wvumedicine/src/pages/404.js" /* webpackChunkName: "component---node-modules-giraldomac-gatsby-theme-wvumedicine-src-pages-404-js" */),
  "component---src-pages-about-us-accreditations-designations-index-js": () => import("./../../../src/pages/about-us/accreditations-designations/index.js" /* webpackChunkName: "component---src-pages-about-us-accreditations-designations-index-js" */),
  "component---src-pages-about-us-centers-dato-cms-center-slug-js": () => import("./../../../src/pages/about-us/centers/{datoCmsCenter.slug}.js" /* webpackChunkName: "component---src-pages-about-us-centers-dato-cms-center-slug-js" */),
  "component---src-pages-about-us-highlighted-provider-dato-cms-cancer-provider-slug-js": () => import("./../../../src/pages/about-us/highlighted-provider/{datoCmsCancerProvider.slug}.js" /* webpackChunkName: "component---src-pages-about-us-highlighted-provider-dato-cms-cancer-provider-slug-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-inside-the-institute-dato-cms-cancer-inside-institute-slug-js": () => import("./../../../src/pages/about-us/inside-the-institute/{datoCmsCancerInsideInstitute.slug}.js" /* webpackChunkName: "component---src-pages-about-us-inside-the-institute-dato-cms-cancer-inside-institute-slug-js" */),
  "component---src-pages-about-us-leadership-dato-cms-cancer-leadership-slug-js": () => import("./../../../src/pages/about-us/leadership/{datoCmsCancerLeadership.slug}.js" /* webpackChunkName: "component---src-pages-about-us-leadership-dato-cms-cancer-leadership-slug-js" */),
  "component---src-pages-about-us-podcasts-dato-cms-cancer-podcast-show-slug-js": () => import("./../../../src/pages/about-us/podcasts/{datoCmsCancerPodcastShow.slug}.js" /* webpackChunkName: "component---src-pages-about-us-podcasts-dato-cms-cancer-podcast-show-slug-js" */),
  "component---src-pages-about-us-podcasts-index-js": () => import("./../../../src/pages/about-us/podcasts/index.js" /* webpackChunkName: "component---src-pages-about-us-podcasts-index-js" */),
  "component---src-pages-about-us-programs-dato-cms-cancer-program-slug-js": () => import("./../../../src/pages/about-us/programs/{datoCmsCancerProgram.slug}.js" /* webpackChunkName: "component---src-pages-about-us-programs-dato-cms-cancer-program-slug-js" */),
  "component---src-pages-about-us-programs-mobile-cancer-screening-program-dato-cms-cancer-mobile-program-slug-js": () => import("./../../../src/pages/about-us/programs/mobile-cancer-screening-program/{datoCmsCancerMobileProgram.slug}.js" /* webpackChunkName: "component---src-pages-about-us-programs-mobile-cancer-screening-program-dato-cms-cancer-mobile-program-slug-js" */),
  "component---src-pages-about-us-teams-dato-cms-cancer-team-slug-js": () => import("./../../../src/pages/about-us/teams/{datoCmsCancerTeam.slug}.js" /* webpackChunkName: "component---src-pages-about-us-teams-dato-cms-cancer-team-slug-js" */),
  "component---src-pages-academics-index-js": () => import("./../../../src/pages/academics/index.js" /* webpackChunkName: "component---src-pages-academics-index-js" */),
  "component---src-pages-bbcc-24-js": () => import("./../../../src/pages/bbcc24.js" /* webpackChunkName: "component---src-pages-bbcc-24-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-dato-cms-cancer-generic-page-slug-js": () => import("./../../../src/pages/{datoCmsCancerGenericPage.slug}.js" /* webpackChunkName: "component---src-pages-dato-cms-cancer-generic-page-slug-js" */),
  "component---src-pages-for-medical-professionals-index-js": () => import("./../../../src/pages/for-medical-professionals/index.js" /* webpackChunkName: "component---src-pages-for-medical-professionals-index-js" */),
  "component---src-pages-for-medical-professionals-refer-patient-js": () => import("./../../../src/pages/for-medical-professionals/refer-patient.js" /* webpackChunkName: "component---src-pages-for-medical-professionals-refer-patient-js" */),
  "component---src-pages-for-medical-professionals-transfer-patient-js": () => import("./../../../src/pages/for-medical-professionals/transfer-patient.js" /* webpackChunkName: "component---src-pages-for-medical-professionals-transfer-patient-js" */),
  "component---src-pages-giving-dato-cms-cancer-giving-opportunity-slug-js": () => import("./../../../src/pages/giving/{datoCmsCancerGivingOpportunity.slug}.js" /* webpackChunkName: "component---src-pages-giving-dato-cms-cancer-giving-opportunity-slug-js" */),
  "component---src-pages-giving-donor-stories-dato-cms-cancer-donor-slug-js": () => import("./../../../src/pages/giving/donor-stories/{datoCmsCancerDonor.slug}.js" /* webpackChunkName: "component---src-pages-giving-donor-stories-dato-cms-cancer-donor-slug-js" */),
  "component---src-pages-giving-events-dato-cms-cancer-event-slug-js": () => import("./../../../src/pages/giving/events/{datoCmsCancerEvent.slug}.js" /* webpackChunkName: "component---src-pages-giving-events-dato-cms-cancer-event-slug-js" */),
  "component---src-pages-giving-index-js": () => import("./../../../src/pages/giving/index.js" /* webpackChunkName: "component---src-pages-giving-index-js" */),
  "component---src-pages-glbcrs-js": () => import("./../../../src/pages/glbcrs.js" /* webpackChunkName: "component---src-pages-glbcrs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patients-visitors-cancers-we-treat-dato-cms-cancer-treat-slug-js": () => import("./../../../src/pages/patients-visitors/cancers-we-treat/{datoCmsCancerTreat.slug}.js" /* webpackChunkName: "component---src-pages-patients-visitors-cancers-we-treat-dato-cms-cancer-treat-slug-js" */),
  "component---src-pages-patients-visitors-index-js": () => import("./../../../src/pages/patients-visitors/index.js" /* webpackChunkName: "component---src-pages-patients-visitors-index-js" */),
  "component---src-pages-patients-visitors-resources-index-js": () => import("./../../../src/pages/patients-visitors/resources/index.js" /* webpackChunkName: "component---src-pages-patients-visitors-resources-index-js" */),
  "component---src-pages-patients-visitors-services-dato-cms-wvum-service-slug-js": () => import("./../../../src/pages/patients-visitors/services/{datoCmsWvumService.slug}.js" /* webpackChunkName: "component---src-pages-patients-visitors-services-dato-cms-wvum-service-slug-js" */),
  "component---src-pages-prevention-control-patient-stories-dato-cms-cancer-patient-story-slug-js": () => import("./../../../src/pages/prevention-control/patient-stories/{datoCmsCancerPatientStory.slug}.js" /* webpackChunkName: "component---src-pages-prevention-control-patient-stories-dato-cms-cancer-patient-story-slug-js" */),
  "component---src-pages-research-dato-cms-cancer-laboratory-slug-js": () => import("./../../../src/pages/research/{datoCmsCancerLaboratory.slug}.js" /* webpackChunkName: "component---src-pages-research-dato-cms-cancer-laboratory-slug-js" */),
  "component---src-pages-research-dato-cms-cancer-research-page-slug-js": () => import("./../../../src/pages/research/{datoCmsCancerResearchPage.slug}.js" /* webpackChunkName: "component---src-pages-research-dato-cms-cancer-research-page-slug-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-researchers-dato-cms-cancer-researcher-slug-js": () => import("./../../../src/pages/research/researchers/{datoCmsCancerResearcher.slug}.js" /* webpackChunkName: "component---src-pages-research-researchers-dato-cms-cancer-researcher-slug-js" */)
}

